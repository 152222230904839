import React, { useEffect } from "react";
import { Box, Container, Typography, Card, Grid, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Logo from "../general/logoSign";
import { useAuth0 } from "@auth0/auth0-react";

const LoginWrapper = styled(Box)(
    () => `
      overflow: auto;
      flex: 1;
      overflow-x: hidden;
      align-items: center;
  `
);

const Login = () => {

    const { loginWithRedirect } = useAuth0();

    useEffect(() => {

        var orgId = new URLSearchParams(window.location.search).get('organization');

        //Auto redirect to organization login page if id provided in query string
        if(orgId != null) {

            //Check if invitation provided as well
            var inviteTicket = new URLSearchParams(window.location.search).get('invitation');

            if(inviteTicket != null) {
                loginWithRedirect({
                    organization: orgId,
                    invitation: inviteTicket
                });
            }

            else {
                loginWithRedirect({
                    organization: orgId
                });
            }
        }
    });

    return (
        <LoginWrapper>
            <Container maxWidth="md" sx={{ textAlign:'center' }}>
                <Box display="flex" justifyContent="center" py={5} alignItems="center">
                    <Logo />
                </Box>
                <Card sx={{ p: 10, mb: 10, borderRadius: 12 }}>
                    <Grid spacing={{ xs: 6, md: 10 }} justifyContent="center" alignItems="center" container>
                        <Grid item xs={12}>
                            <Typography variant='h1' sx={{ mb: 2 }}>Digibin</Typography>
                            <Typography sx={{ lineHeight: 1.5, pb: 4 }}
                                variant="h6"
                                color="text.secondary"
                                fontWeight="normal">
                                Please login using the link below
                            </Typography>
                            <Button size="large" variant="contained" onClick={() => loginWithRedirect()}>
                                Login
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </Container>
        </LoginWrapper>
    );
};

export default Login