import React from 'react';
import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { ListItem, Button, Badge } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link as GatsbyLink } from 'gatsby';
import { styled } from "@mui/material/styles";

import { RootState } from '../../store/store';
import { resetState } from '../../store/quoteSlice';

interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  badge?: string;
  open?: boolean;
  active?: boolean;
  name: string;
  handler?: any;
}

const Link = styled(GatsbyLink) (() =>
  'text-decoration: none'
)

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  active,
  name,
  handler,
  ...rest
}) => {

  //COMPONENT STATE

  const showSidebar = useSelector((state: RootState) => state.sidebarNav.show);
  
  //GLOBAL STATE

  const dispatch = useDispatch();

  //HANDLERS

  const handleMenuItemClick = () => {
    dispatch(resetState());
  }

  if (showSidebar) {
    return (
      <ListItem component="div" key={name} {...rest}>
        <Link to={link ?? ""}>
          <Button startIcon={Icon && <Icon />} onClick={() => handleMenuItemClick()}>
            {name}
            {badge && <Badge badgeContent={badge} />}
          </Button>
        </Link>
      </ListItem>
    );
  }

  else {
    return (
      <ListItem component="div" key={name} {...rest}>
        <Link to={link ?? ""}>
          <Button startIcon={Icon && <Icon />} sx={{ '.MuiButton-startIcon': { margin:'auto !important' } }} onClick={() => handleMenuItemClick()}>
          </Button>
        </Link>
      </ListItem>
    );
  }
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired,
  handler: PropTypes.func
};

SidebarMenuItem.defaultProps = {
  open: false,
  active: false,
};

export default SidebarMenuItem;
