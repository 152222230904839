import React from 'react';
import { Box, Hidden, Tooltip, IconButton } from '@mui/material';
import { MenuTwoTone as MenuTwoToneIcon, CloseTwoTone as CloseTwoToneIcon } from '@mui/icons-material';
import { BoxProps as MuiBoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import UserInfo from './userInfo';
import Logo from './logo';
import { sidebarToggle } from '../store/sidebarNavSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/store';

interface BoxProps extends MuiBoxProps {
  open?: boolean;
}

const HeaderWrapperProps = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})<BoxProps>(({ theme }) => ({
  
  transition: theme.transitions.create(['width', 'margin', 'left'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.complex,
  }),

}));

const HeaderWrapper = styled(HeaderWrapperProps) (
  ({ theme, open }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 5;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${ open ? theme.sidebar.width : '100px' };
            width: auto;
        }
`
);

const Header = () => {
  
  const showSidebar = useSelector((state: RootState) => state.sidebarNav.show);

  const dispatch = useDispatch();

  return (
    <HeaderWrapper display="flex" alignItems="center" open={showSidebar}>
        <Box display="flex" alignItems="center">
          <Hidden lgUp>
            <Tooltip arrow title="Toggle Menu">
              <IconButton color="primary" onClick={() => dispatch(sidebarToggle(!showSidebar))}>
                {!showSidebar ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
              </IconButton>
            </Tooltip>
          </Hidden>
          <Logo /> 
        </Box>
        <Box display="flex" alignItems="center">
          <UserInfo />
        </Box>
    </HeaderWrapper>
  );
}

export default Header;
