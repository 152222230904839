import React from 'react';
import { Box, Drawer, Tooltip, IconButton, Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
import SidebarMenu from './sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { sidebarToggle } from '../../store/sidebarNavSlice';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

const TopSection = styled(Box)(
  ({ theme }) => `
        display: flex;
        height: 88px;
        align-items: center;
        margin: 0 ${theme.spacing(2)} ${theme.spacing(2)};
        border-bottom: ${theme.sidebar.dividerBg} solid 1px;
`
);

const Sidebar = () => {
  
    const showSidebar = useSelector((state: RootState) => state.sidebarNav.show);

    const dispatch = useDispatch();

    const SidebarWrapper = styled(Box)(
        ({ theme }) => `
              width: ${ !showSidebar ? '100px' : theme.sidebar.width };
              color: ${theme.sidebar.textColor};
              background: ${theme.sidebar.background};
              box-shadow: ${theme.sidebar.boxShadow};
              height: 100%;
              
              @media (min-width: ${theme.breakpoints.values.lg}px) {
                  position: fixed;
                  z-index: 10;
                  border-top-right-radius: ${theme.general.borderRadius};
                  border-bottom-right-radius: ${theme.general.borderRadius};
              }
      `
    );

    return (
        <>
            <Hidden lgDown>
                <Drawer
                    anchor="left"
                    open={showSidebar}
                    onClose={() => dispatch(sidebarToggle(!showSidebar))}
                    variant="permanent"
                    elevation={9}
                    >
                    <SidebarWrapper>
                        <TopSection>
                            <Tooltip arrow title="Toggle Menu">
                                <IconButton color="primary" onClick={() => dispatch(sidebarToggle(!showSidebar))} sx={{ margin: showSidebar ? 'none' : 'auto' }}>
                                    { !showSidebar ? <MenuTwoToneIcon /> : <CloseTwoToneIcon /> } 
                                </IconButton>
                            </Tooltip>
                        </TopSection>
                        <SidebarMenu />
                    </SidebarWrapper>
                </Drawer>
            </Hidden>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    open={showSidebar}
                    onClose={() => dispatch(sidebarToggle(!showSidebar))}
                    variant="temporary"
                    elevation={9}
                    >
                    <SidebarWrapper>
                        <TopSection>
                            <Tooltip arrow title="Toggle Menu">
                                <IconButton color="primary" onClick={() => dispatch(sidebarToggle(!showSidebar))}>
                                    { !showSidebar ? <MenuTwoToneIcon /> : <CloseTwoToneIcon /> } 
                                </IconButton>
                            </Tooltip>
                        </TopSection>
                        <SidebarMenu />
                    </SidebarWrapper>
                </Drawer>
            </Hidden>
        </>
    );
}

export default Sidebar;
