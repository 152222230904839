import * as React from 'react';
import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import ThemeProviderWrapper from '../theme/themeProvider';
import { CssBaseline, Box } from '@mui/material';
import "@fontsource/inter";
import { useAuth0 } from '@auth0/auth0-react';

import Header from './header';
import Sidebar from './sidebar';
import Login from '../components/login/login';

interface LayoutProps {
    children?: ReactNode;
    paddingLeft?: string;
}

const MainContent = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
`
);

const MainWrapper = styled(Box)(
  ({ theme, paddingLeft }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: ${paddingLeft ? paddingLeft : theme.sidebar.width};
        }
`
);

const Layout: FC<LayoutProps> = ({ children, paddingLeft }) => {

  const { isLoading, isAuthenticated } = useAuth0();

  if(isLoading) {
    return (
      <Box>Loading</Box>
    )
  }

  else if (!isAuthenticated) {
    return (
      <ThemeProviderWrapper>
        <CssBaseline />
        <main>
          <Login></Login>
        </main>
      </ThemeProviderWrapper>
    )
  }

  else {
    
    return (
      <ThemeProviderWrapper>
        <CssBaseline />
        <main>
          <Sidebar />
          <MainWrapper paddingLeft={paddingLeft}>
            <Header />
            <MainContent>
              {children}
            </MainContent>
          </MainWrapper>
        </main>
      </ThemeProviderWrapper>
    )
  }
}

export default Layout;