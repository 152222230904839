import { ReactNode } from 'react';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
  handler?: any;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Home',
        link: '/',
        icon: HomeTwoToneIcon
      }
    ]
  },
  {
    heading: 'Quotes',
    items: [
      {
        name: 'My Leads',
        link: '/viewleads',
        icon: AccountCircleTwoToneIcon
      },
      {
        name: 'New Quote',
        icon: AddCircleTwoToneIcon,
        link: '/quote'
      }
    ]
  }
];

export default menuItems;
